import React from 'react'
import { Link } from 'gatsby'
import './PostSummary.scss'
import Timestamp from './timestamp'

export default function PostySummary ({ post }) {
  return (
    <article className='post summary'>
      <h2>
        <Link to={`/posts${post.node.fields.slug}`}>{post.node.frontmatter.title}</Link>
        <small><Timestamp date={post.node.frontmatter.date} /></small>
      </h2>
      <div>{post.node.excerpt}</div>
    </article>
  )
}
