import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostSummary from '../components/PostSummary'
import Pagination from '../components/Pagination'
import SEO from '../components/seo'

export default function BlogIndex ({ data, pageContext: { page, totalPages } = {} } = {}) {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO
        title={`Max Polun's blog`}
        description='A blog mostly about software'
      />
      {posts.map(p => <PostSummary key={p.node.fields.slug} post={p} />)}
      <Pagination currentPage={page} totalPages={totalPages} />
    </Layout>
  )
}
export const query = graphql`
  query($perPage: Int!, $offset: Int!) {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $perPage
      skip: $offset
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 500)
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
