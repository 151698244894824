import React from 'react'
import './Pagination.scss'
import PossiblyLink from './PossiblyLink'

export default function Pagination ({ currentPage, totalPages }) {
  const hasPrev = currentPage > 0
  const hasNext = currentPage !== totalPages
  return (
    <div className='pagination'>
      <PossiblyLink className='pagination-link' disabled={!hasPrev} to='/'>Most Recent</PossiblyLink>
      <PossiblyLink className='pagination-link' disabled={!hasPrev} to={`/page/${currentPage - 1}`}>More Recent</PossiblyLink>
      <PossiblyLink className='pagination-link' disabled={!hasNext} to={`/page/${currentPage + 1}`}>Older</PossiblyLink>
      <PossiblyLink className='pagination-link' disabled={!hasNext} to={`/page/${totalPages}`}>Oldest</PossiblyLink>
    </div>
  )
}
